<template>
    <div>
        <v-card>
            <s-toolbar label="Asistencia Remota" dark color="#8e8f91">
                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn 
                            v-on="on"
                            text
                            black
                            small
                        > 
                            <i class="fas fa-stopwatch"></i> {{ timer.display }}
                        </v-btn>
                    </template>
                    <span>Tiempo</span>
                </v-tooltip>
            </s-toolbar>
            <v-container>
                <v-row>
                    <v-col>
                        <v-data-table
                            item-key="RtwID"
                            dense
                            fixed-header
                            :items="items"
                            :headers="config.headers"
                            :items-per-page="10"
                            v-model="selected"
                        >
                        <template v-slot:[`item.Actions`]="{ item }">
                            <!-- Mostrar botones solo si NO ha completado ambas marcas -->
                            <template v-if="item.HasCompletedAttendance === 0 && item.AttendanceState !== 'I' && $fun.formatDateView(item.RtwDate) === $fun.formatDateView(item.CurrentDate)">
                                <!-- Botón de Entrada -->
                                <v-btn      
                                    v-if="item.AttendanceState === 'E'" 
                                    x-small 
                                    color="info" 
                                    @click="RegisterAttendance(item)"
                                >
                                    <v-icon>mdi-play-circle</v-icon> Entrada
                                </v-btn>

                                <!-- Botón de Salida -->
                                <v-btn 
                                    v-if="item.AttendanceState === 'S'" 
                                    x-small 
                                    color="error" 
                                    @click="RegisterAttendance(item)"
                                >
                                    <v-icon>mdi-stop-circle</v-icon> Salida
                                </v-btn>
                            </template>

                            <!-- Mostrar estado "Asistió" cuando ya completó asistencia -->
                            <template v-else-if="item.HasCompletedAttendance === 1">
                                <v-chip color="success" x-small dark>
                                    <v-icon left>mdi-check-circle</v-icon>
                                    Asistió
                                </v-chip>
                            </template>

                            <!-- Mostrar estado "Inasistencia" si la fecha programada ya pasó y no hay registro de asistencia -->
                            <template v-else-if="item.AttendanceState === 'I'">
                                <v-chip color="red" x-small dark>
                                    <v-icon left>mdi-close-circle</v-icon>
                                    Inasistencia
                                </v-chip>
                            </template>
                        </template>

                        <template v-slot:[`item.RtwDate`]="{ item }">
                            {{ $fun.formatDateView(item.RtwDate) }}
                        </template>
                        <template v-slot:[`item.TdrDateBegin`]="{ item }">
                            {{ $fun.formatDateTimeView(item.TdrDateBegin) }}
                        </template>
                        <template v-slot:[`item.TdrDateEnd`]="{ item }">
                            {{ $fun.formatDateTimeView(item.TdrDateEnd) }}
                        </template>
                        <template v-slot:[`item.TdrTimeBegin`]="{ item }">
                            {{ $fun.formatTimeView(item.TdrDateBegin) }}
                        </template>
                        <template v-slot:[`item.TdrTimeEnd`]="{ item }">
                            {{ $fun.formatTimeView(item.TdrDateEnd) }}
                        </template>

                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>

import { _ } from 'core-js';
import SText from '../../../components/Utils/SText.vue';
import _sAttendanceRemoteService from '@/services/HumanResource/Compensation/HmnAttendanceRemoteService.js';

export default {
    components: {
        SText,
    },
    data() {
        return {
            items: [],
            selected: [],
            obj: {},
            filter: {
                WkrID: 0
            },
            timer: {
                hours: 0,
                minutes: 0,
                seconds: 0,
                interval: null,
                display: "00:00:00", // Añadimos una propiedad para el display
                startDate: null      // Fecha de inicio para el cronómetro
            },
            entryDate: null,
            exitDate: null,
            config: {
                headers: [
                    {text: "ID"									, value: "RtwID"				},
                    {text: "N° Doc"								, value: "PrsDocumentNumber"	},
                    {text: "Nombres y Apellidos Completos"		, value: "NtpFullName"			},
                    {text: "Descripcion"		                , value: "RtwDescription"		},
                    {text: "Area"								, value: "AreName"				},
                    {text: "Fecha de programación"			    , value: "RtwDate"			    },
                    {text: "Fecha Entrada"			            , value: "TdrDateBegin"		    },
                    {text: "Fecha Salida"			            , value: "TdrDateEnd"		    },
                    // {text: "Hora Entrada"			            , value: "TdrTimeBegin"		    },
                    // {text: "Hora Salida"			            , value: "TdrTimeEnd"		    },
                    {text: "Asistencia"			                , value: "Actions"			    },
                    // {text: "AttendanceState"			                , value: "AttendanceState"		},
                    // {text: "HasCompletedAttendance"			                , value: "HasCompletedAttendance"		},
                ],
            },
        };
    },
    methods: {
        load() {
            _sAttendanceRemoteService
            .list(this.filter, this.$fun.getUserID())
            .then((r) => {
                this.items = r.data;
                
                // Buscar si hay un registro activo (entrada sin salida)
                const activeEntry = this.items.find(item => 
                    item.AttendanceState === "S" && 
                    item.HasCompletedAttendance === 0 && 
                    item.TdrDateEnd === null
                );
                
                // Si encontramos un registro activo, iniciamos el cronómetro
                if (activeEntry) {
                    this.startTimer(activeEntry.TdrDateBegin); // activar cronometro aqui
                } else {
                    // Si no hay entrada activa, mostramos 00:00:00
                    this.timer.display = "00:00:00";
                    // Si había un cronómetro corriendo, lo detenemos
                    if (this.timer.interval) {
                        clearInterval(this.timer.interval);
                        this.timer.interval = null;
                    }
                }
            });
        },
        
        // Método para iniciar el cronómetro
        startTimer(startDateString) {
            // Guardamos la fecha de inicio
            this.timer.startDate = new Date(startDateString);
            
            // Detenemos cualquier intervalo existente
            if (this.timer.interval) {
                clearInterval(this.timer.interval);
            }
            
            // Función para actualizar el cronómetro
            const updateTimer = () => {
                const now = new Date();
                const diff = now - this.timer.startDate; // diferencia en milisegundos
                
                // Convertir a horas, minutos y segundos
                const hours = Math.floor(diff / 3600000);
                const minutes = Math.floor((diff % 3600000) / 60000);
                const seconds = Math.floor((diff % 60000) / 1000);
                
                // Actualizar el estado
                this.timer.hours = hours;
                this.timer.minutes = minutes;
                this.timer.seconds = seconds;
                
                // Formatear para mostrar (con ceros a la izquierda)
                this.timer.display = 
                    `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            };
            
            // Ejecutar inmediatamente y luego cada segundo
            updateTimer();
            this.timer.interval = setInterval(updateTimer, 1000);
        },
        RegisterAttendance(item) {
            this.obj = {
                RtwID: item.RtwID,
                WkrID: item.WkrID,
                NtpID: this.$fun.getUserInfo().NtpID,
                TdrTypeAttendance: item.AttendanceState,
                UsrCreateID: this.$fun.getUserID()
            }
            this.$fun.alert(`¿Estas seguro de registrar?`, "question")
            .then((val) => {
                if (val.value) {
                    _sAttendanceRemoteService
                        .save(this.obj, this.$fun.getUserID())
                        .then((r) => {
                            console.log(r);
                            this.load();
                        })
                }
            });
        },
    },
    mounted() {
        this.filter.NtpID = this.$fun.getUserInfo().NtpID;
        this.load();
    },
    beforeDestroy() {
        if (this.timer.interval) {
            clearInterval(this.timer.interval);
        }
    }
}
</script>
