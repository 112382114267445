import Service from "../../Service";
const resource = "HmnAttendanceRemote/";

export default {

    list(parameters,requestID) {
        return Service.post(resource + "list", parameters, {
            params: { requestID: requestID },
        });
    },

    save(parameters,requestID) {
        return Service.post(resource + "save", parameters, {
            params: { requestID: requestID },
        });
    },
};